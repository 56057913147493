<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  name="Title"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Title" label-for="mc-title">
                    <b-form-input
                      id="mc-title"
                      placeholder="Title"
                      v-model="model.title"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item title="Section">
                    <b-row>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section</h5>
                        <span v-for="(page, idx) in model.sections" :key="idx">
                          <b-row>
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Title ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Title"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Title"
                                    v-model="model.sections[idx].title"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col md="6">
                              <quill-editor
                                v-model="model.information"
                                :options="editorOption"
                              >
                              </quill-editor>
                            </b-col> -->
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Description ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Description"
                                  label-for="mc-title"
                                >
                                  <quill-editor
                                    v-model="model.sections[idx].body"
                                    :options="editorOption"
                                  >
                                  </quill-editor>
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <button
                            type="button"
                            class="btn btn-danger mr-2"
                            @click="deleteSection(idx)"
                            v-if="model.sections.length > 1"
                          >
                            <i class="fa fa-trash"></i> Delete Row Sections</button
                          >&nbsp;
                          <div class="divider my-1">
                            <div class="divider-text">End of Row Sections</div>
                          </div>
                        </span>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="addMoreSection"
                        >
                          Add More Section
                        </b-button>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>

            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'content-privacy' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    quillEditor,
    BAlert,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [{ font: [] }, { size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["direction", { align: [] }],
            ["clean"],
            ["link"],
          ],
        },
        theme: "snow",
      },
      collapseType: "margin",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "content/privacy-policy",
        redirect: "content-privacy",
        language: "languages",
        upload: "misc/upload",
      },
      model: {
        title: "Privacy Policy",
        sections: [
          {
            title: "",
            body: "",
          },
        ],
      },
      messages: "",
      languages: [],
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    addMoreSection() {
      let _ = this;
      _.model.sections.push({
        title: null,
        body: null,
      });
    },

    deleteSection(x) {
      let _ = this;
      _.model.sections.splice(x, 1);
    },

    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.title = _.model.header.title;
            _.model.subtitle = _.model.header.subtitle;
          });
      }
    },
    save() {
      let _ = this;

      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .patch(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
  watch: {
    "model.code"(newValue) {
      this.model.code = newValue.toLowerCase();
    },
  },
};
</script>
