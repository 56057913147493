var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('app-collapse',{attrs:{"accordion":"","type":_vm.collapseType}},[_c('app-collapse-item',{attrs:{"title":"Section"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"font-600 mb-2"},[_vm._v("Section")]),_vm._l((_vm.model.sections),function(page,idx){return _c('span',{key:idx},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Title ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.sections[idx].title),callback:function ($$v) {_vm.$set(_vm.model.sections[idx], "title", $$v)},expression:"model.sections[idx].title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Description ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"mc-title"}},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.sections[idx].body),callback:function ($$v) {_vm.$set(_vm.model.sections[idx], "body", $$v)},expression:"model.sections[idx].body"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),(_vm.model.sections.length > 1)?_c('button',{staticClass:"btn btn-danger mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteSection(idx)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Delete Row Sections")]):_vm._e(),_vm._v("  "),_c('div',{staticClass:"divider my-1"},[_c('div',{staticClass:"divider-text"},[_vm._v("End of Row Sections")])])],1)}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.addMoreSection}},[_vm._v(" Add More Section ")])],2)],1)],1)],1)],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'content-privacy' }}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }